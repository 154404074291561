import React, { useState, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Typography, Button, Collapse,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  '& .EDText': {
    color: theme.palette.grey['400'],
  },
  '& .EDExpandBtn': {
    paddingLeft: 0,
    textTransform: 'capitalize',
    color: theme.palette.grey[500],
    fontWeight: 500,
  },
  '& a': {
    color: theme.palette.grey[500],
    fontWeight: 700,
  },
  '& .EDArrowIcon': {
    color: theme.palette.grey[400],
  },
}));

const ExpandableDescription = (props) => {
  const { t } = useTranslation('fe_er_expandable_description_component');
  const isCrawler = useSelector((state) => state.globalData.isCrawler);

  const {
    description,
    collapsedSize = 80,
    className,
    autoExpand = false,
  } = props;

  const [isExpanded, setIsExpanded] = useState(isCrawler || autoExpand);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const descriptionRef = useRef(null);
  const [descriptionHeight, setDescriptionHeight] = useState(null);

  useEffect(() => {
    setDescriptionHeight(descriptionRef.current.clientHeight);
  }, [descriptionRef, descriptionHeight]);

  return (
    <Root className={className}>
      <Box>
        <Collapse
          in={isExpanded}
          collapsedSize={
            descriptionHeight < collapsedSize
              ? descriptionHeight
              : collapsedSize
          }>
          <Typography
            component="div"
            className="EDText"
            variant="body1"
            ref={descriptionRef}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Collapse>

        {descriptionHeight > collapsedSize && (
          <Button
            className="EDExpandBtn"
            endIcon={
              isExpanded ? (
                <KeyboardArrowUpIcon className="EDArrowIcon" />
              ) : (
                <KeyboardArrowDownIcon className="EDArrowIcon" />
              )
            }
            onClick={handleExpand}>
            {isExpanded
              ? t('fe_er_expandable_description_component:read_less')
              : t('fe_er_expandable_description_component:read_more')}
          </Button>
        )}
      </Box>
    </Root>
  );
};

export default ExpandableDescription;
