import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBox = styled(Box)(({ theme }) => ({
  '&.TourPackage': {
    position: 'relative',
    height: theme.spacing(36.625),
    width: '100%',
    background:
      'linear-gradient(180deg, rgba(237, 193, 79, 0.62) 68.77%, rgba(237, 193, 79, 0.00) 100%)',
    backgroundSize: 'cover',
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('lg')]: {
      height: theme.spacing(35.125),
    },
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(30),
    },
    '& .textContainer': {
      padding: theme.spacing(0, 2),
      textAlign: 'center',
      position: 'absolute',
      left: 0,
      top: theme.spacing(7.5),
      width: '100%',
      zIndex: 1,
      '& svg': {
        width: theme.spacing(10.25),
        height: theme.spacing(10.25),
        [theme.breakpoints.down('md')]: {
          width: theme.spacing(7.25),
          height: theme.spacing(7.25),
        },
      },
      '& .MuiTypography-h3': {
        margin: theme.spacing(1, 0),
      },
    },
    '& .overlay': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      zIndex: 0,
      transition: 'background-color .8s ease',
      backgroundColor: 'transparent',
    },
    '&:hover': {
      cursor: 'pointer',
      '& .overlay': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '& .IBimgbox': {
      zIndex: -1,
    },
    '& .TPTitle': {
      fontSize: theme.spacing(2.5),
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        fontSize: theme.spacing(2.25),
      },
    },
  },
}));
