import { styled } from '@mui/material/styles';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';

const StyledDestinationCardLink = styled(NavigatorLink)(({ theme }) => ({
  '&.DCRoot': {
    position: 'relative',
    height: theme.spacing(36),
    display: 'flex',
    '&:hover': {
      '& .DCHoverLayer': {
        opacity: 0.89,
        background:
          'linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, #000 100%)',
      },
      '& .DCFooterBox': {
        backgroundImage: 'none',
      },
    },
    '& .DCFooterBox': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
      backgroundImage: `linear-gradient(to bottom, ${theme.palette.secondary.dark}80, ${theme.palette.secondary.dark})`,
      position: 'absolute',
      zIndex: 2,
      width: '100%',
      bottom: '0',
      color: theme.palette.common.fogWhite,
      boxSizing: 'border-box',
      height: theme.spacing(9),
      transition: 'all 200ms ease-in-out',
    },
    '& .DCFooterTitle': {
      textTransform: 'uppercase',
      fontWeight: 700,
    },
    '& .DCFooterIcon': {
      '& svg': {
        height: theme.spacing(5),
        width: theme.spacing(5),
      },
    },
    '& .DCHoverLayer': {
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'transparent',
      transition: 'background 200ms ease-in-out',
    },
  },
}));

export default StyledDestinationCardLink;
